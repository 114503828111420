export const convertPropsToObject = item => {
  const props = item.properties.reduce((merged, current) => {
    var _a;
    if (
      ((_a =
        current === null || current === void 0 ? void 0 : current.value) ===
        null || _a === void 0
        ? void 0
        : _a.__typename) === 'StringValue'
    ) {
      merged[current.name] = {
        type: current.type,
        value: current.value.value,
        __typename: 'StringValue'
      };
    } else {
      merged[current.name] = { type: current.type, value: current.value };
    }
    return merged;
  }, {});
  return props;
};

export function extractValues(items) {
  const reduced = Object.keys(items).reduce((acc, key) => {
    if (items[key] && items[key].value) {
      acc[key] = items[key].value;
    }
    return acc;
  }, {});
  return reduced;
}
