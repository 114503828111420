import VerticalCardGrid from './Components/VerticalCardGrid';
import VerticalCardGridLayout from './Root/VerticalCardGrid';
import HTMLLayout from './Root/HTML';
import HTML from './Components/HTML';
import CardsLayout from './Root/Cards';
import Card from './Components/Card';
import Layout from './Components/Layout';
import SideBySide from './Components/SideBySide';
import SideBySideLayout from './Root/SideBySide';
import Hero from './Components/Hero';
import HeroLayout from './Root/Hero';
import ThreeImageBlocks from './Components/ThreeImageBlocks';
import ThreeImageBlocksLayout from './Root/ThreeImageBlocks';
import HeroCategory from './Root/HeroCategory';
import RichText from './Root/RichText';
import ImageColumns from './Root/ImageColumns';
import ImageColumn from './Components/ImageColumn';
import MediaText from './Root/MediaText';
import FAQ from './Root/FAQ';
import FAQItem from './Components/FAQItem';
import GetanewsletterSignup from '../../Getanewsletter/GetanewsletterSignup';
import CategoryText from '../../CategoryPage/CategoryText';
import CategoryTextItem from '../../CategoryPage/CategoryTextItem';

export const shared = {
  'COMPONENT-LAYOUT': () => null,
  LAYOUT: Layout,
  'COMPONENT-VERTICALCARDGRID': VerticalCardGrid,
  'VERTICAL CARD GRID': VerticalCardGridLayout,
  'COMPONENT-HTML': HTML,
  HTML: HTMLLayout,
  CARDS: CardsLayout,
  'COMPONENT-CARD': Card,
  'COMPONENT-SIDEBYSIDE': SideBySide,
  'SIDE BY SIDE': SideBySideLayout,
  'COMPONENT-HEROIMAGE': Hero,
  'Hero Category': HeroCategory,
  MediaText: MediaText,
  ImageColumns: ImageColumns,
  FAQ: FAQ,
  'COMPONENT-FAQ-ITEM': FAQItem,
  'COMPONENT-IMAGE-COLUMN': ImageColumn,
  RichText: RichText,
  HERO: HeroLayout,
  'THREE IMAGE BLOCKS': ThreeImageBlocksLayout,
  'COMPONENT-THREEIMAGEBLOCKS': ThreeImageBlocks,
  'NEWSLETTER SIGNUP': GetanewsletterSignup,
  categoryText: CategoryText,
  categoryTextItem: CategoryTextItem
};

export default shared;
