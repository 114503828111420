import React from 'react';
import { extractValues } from '../Utils/helpers';
import { styled } from 'linaria/react';

function CategoryTextItem(data) {
  const items = extractValues(data);
  return (
    <div>
      <Header>{items.header}</Header>
      <Text>{items.paragraph}</Text>
    </div>
  );
}

export default CategoryTextItem;

const Text = styled.p`
  font-weight: 300;
`;

const Header = styled.h5`
  margin-bottom: 0.25em;
`;
