import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';

function CategoryText(data) {
  if (!data.children || data.children.length === 0) return null;
  return <Grid>{data.children}</Grid>;
}

export default CategoryText;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: ${theme.space[6]};
  margin: ${theme.space[4]} 0;
  ${theme.above.md} {
    margin: ${theme.space[8]} 0;
    grid-template-columns: repeat(2, 1fr);
  }
`;
